import {
  getEdgeNodes,
  graphql,
  newId,
  useHistory,
  useLazyLoadQuery,
  useMutation,
} from "@workflows/runtime-web";
import {
  Button,
  Callout,
  ChoiceList,
  ChoiceListProps,
  Column,
  ColumnBody,
  ColumnFooter,
  FormControl,
  FormInput,
  LinkButton,
  NonIdealState,
  Section,
  SectionBody,
  Select,
  SelectProps,
  Text,
  useForm,
  useFormWatch,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "~/core/useRouter";
import { routes } from "~/de.smartconex.vertragsgenerator/routes";
import { booleanTransform } from "~/de.smartconex.vertragsgenerator/wizard/utils";
import { AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation.graphql";
import { AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery.graphql";
import { Statuses } from "~/core/statuses";
import { VertragsgeneratorRoles } from "~/core/roles";

export interface AppDokgeneratorWorkflowsEmploymentContractv1TypeStepProps {
  collectionId: string;
}

export function AppDokgeneratorWorkflowsEmploymentContractv1TypeStep({
  collectionId,
}: AppDokgeneratorWorkflowsEmploymentContractv1TypeStepProps): JSX.Element {
  const { tenantId, userId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();
  const form = useForm("AppDokgeneratorWorkflowsEmploymentContractv1");
  const values = useFormWatch("AppDokgeneratorWorkflowsEmploymentContractv1", [
    "collectiveAgreement.tradeCompany",
    "collectiveAgreement.active",
    "collectiveAgreement.state",
  ]);

  const { baseUrl } = useRouter();
  const data =
    useLazyLoadQuery<AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery>(
      $AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery,
      { collectionId }
    );

  const [error, setError] = React.useState("");
  const [createDocument, isPending] =
    useMutation<AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation>(
      $AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation
    );

  const handleSubmit = async () => {
    const { isValid } = await form.validate();

    if (!isValid) {
      return;
    }

    const { templateId, title, description, ...values } = form.getValues();

    // This can only happen when the user directly accesses this URL
    // TODO: This should be handled by a model-driven form.
    if (!title) {
      setError(
        t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
      );
      return;
    }

    const input = {
      assignees: [
        {
          assigneeId: userId,
          roleIdentifier: VertragsgeneratorRoles.Assignee,
        },
      ],
      statusIdentifier: Statuses.Open,
      clientMutationId: newId(),
      templateId,
      tenantId,
      title,
      description,
      defaultValues: Object.entries(values)
        .map(([key, value]) => ({
          identifier: key,
          value: JSON.stringify(value),
        }))
        .filter((val) => !!val.value),
    };

    createDocument({
      variables: { input },
      onError(error) {
        console.error(error);
        setError(
          t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
        );
      },
      onCompleted(data) {
        if (data.createDocument?.errors) {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
          );
        } else if (data.createDocument && data.createDocument.document) {
          const documentId = data.createDocument.document.id;
          history.replace(
            routes["wizard.index"].build({ documentId, slug: "intro" })
          );
        } else {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentContractv1.errors.unspecified")
          );
        }
      },
    });
  };

  const collection = data.node;
  const templates = getEdgeNodes(
    collection && collection.items ? collection.items : null
  )
    .filter((tmpl) => tmpl.__typename === "DocumentTemplate")
    .map((tmpl) => ({ label: tmpl.title, value: tmpl.id }));

  if (templates.length === 0) {
    return (
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <NonIdealState
            title="Keine Vertragstypen verfügbar"
            description="Ihr Benutzerkonto hat keine Vorlagen zugeordnet. Bitte kontaktieren Sie den Support um das Problem zu lösen."
          />
        </ColumnBody>
      </Column>
    );
  }

  return (
    <Column focus="primary" width={0.5} background="default">
      <ColumnBody>
        <Section>
          <SectionBody>
            <Text variant="headingLg">Konfiguration</Text>
            {error && <Callout intent="critical">{error}</Callout>}
          </SectionBody>
        </Section>
        <Section>
          <SectionBody>
            <FormControl
              htmlFor="templateId"
              name="templateId"
              label="Vertragstyp"
              description="Bitte wählen Sie einen passenden Vertragstyp."
            >
              <FormInput<ChoiceListProps>
                component={ChoiceList}
                id="templateId"
                name="templateId"
                type="radio"
                options={templates}
                required="Bitte wählen Sie einen passenden Vertragstyp aus."
              />
            </FormControl>

            <FormControl
              htmlFor="collectiveAgreement.tradeCompany"
              name="collectiveAgreement.tradeCompany"
              label="Unternehmensbereich"
              description="Sind Sie ein Unternehmen des Groß- und/oder Außenhandels?"
            >
              <FormInput<ChoiceListProps>
                component={ChoiceList}
                id="collectiveAgreement.tradeCompany"
                name="collectiveAgreement.tradeCompany"
                type="radio"
                options={[
                  {
                    label: "Ja",
                    value: "true",
                  },
                  {
                    label: "Nein",
                    value: "false",
                  },
                ]}
                transform={booleanTransform()}
                required="Bitte füllen Sie dieses Feld aus."
              />
            </FormControl>

            <FormControl
              htmlFor="collectiveAgreement.active"
              name="collectiveAgreement.active"
              label="Vertrag mit Tarifbindung/Tarifanwendung"
              description="Soll ein Vertrag mit Tarifanwendung im Groß- und Außenhandel geschlossen werden?"
            >
              <FormInput<ChoiceListProps>
                component={ChoiceList}
                id="collectiveAgreement.active"
                name="collectiveAgreement.active"
                type="radio"
                options={[
                  {
                    label: "Ja",
                    value: "true",
                  },
                  {
                    label: "Nein",
                    value: "false",
                  },
                ]}
                transform={booleanTransform()}
                required="Bitte füllen Sie dieses Feld aus."
              />
            </FormControl>

            {values["collectiveAgreement.tradeCompany"] == true && (
              <>
                <FormControl
                  htmlFor="collectiveAgreement.state"
                  name="collectiveAgreement.state"
                  label="Bundesland"
                  description="In welchem Bundesland erfolgt die Einstellung?"
                >
                  <FormInput<SelectProps>
                    component={Select}
                    id="collectiveAgreement.state"
                    name="collectiveAgreement.state"
                    required="Bitte füllen Sie dieses Feld aus."
                    options={[
                      {
                        value: "",
                        label: "Bitte wählen Sie eine Option",
                        disabled: true,
                      },
                      {
                        label: "Baden-Württemberg",
                        value: "BW",
                      },
                      {
                        label: "Bayern",
                        value: "BY",
                      },
                      {
                        label: "Hamburg",
                        value: "HH",
                      },
                      {
                        label: "Mecklenburg-Vorpommern",
                        value: "MV",
                      },
                      {
                        label: "Niedersachsen",
                        value: "NI",
                      },
                      {
                        label: "Nordrhein-Westfalen",
                        value: "NW",
                      },
                      {
                        label: "Schleswig-Holstein",
                        value: "SH",
                      },
                    ]}
                  />
                </FormControl>
              </>
            )}
          </SectionBody>
        </Section>
      </ColumnBody>
      <ColumnFooter
        start={
          <LinkButton to={baseUrl}>
            {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.back")}
          </LinkButton>
        }
        end={
          <Button intent="primary" onClick={handleSubmit} isLoading={isPending}>
            {t("AppDokgeneratorWorkflowsEmploymentContractv1.actions.create")}
          </Button>
        }
      />
    </Column>
  );
}

const $AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery = graphql`
  query AppDokgeneratorWorkflowsEmploymentContractv1TypeStepQuery(
    $collectionId: ID!
  ) {
    node(id: $collectionId) {
      ... on Collection {
        name
        items(first: 100) {
          edges {
            cursor
            node {
              id
              ... on DocumentTemplate {
                __typename
                id
                title
                description
                color
              }
            }
          }
        }
      }
    }
  }
`;

const $AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation = graphql`
  mutation AppDokgeneratorWorkflowsEmploymentContractv1TypeStepMutation(
    $input: CreateDocumentInput!
  ) {
    createDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
      }
    }
  }
`;
